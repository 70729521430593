@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .outline-box {
    @apply isolate relative before:w-full before:inset-0 before:block before:absolute before:h-full before:translate-x-2 before:translate-y-2 before:border-2 before:border-current before:-z-10;
  }

  .outline-box-rounded-2xl {
    @apply outline-box before:rounded-2xl;
  }
}

@layer base{
  .scroll  {
    padding-top: 80px;
    position: relative;
  }
  .scroll span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb07 2s infinite;
    animation: sdb07 2s infinite;
    opacity: 0;
    box-sizing: border-box;
  }
  .scroll  span:nth-of-type(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  .scroll  span:nth-of-type(2) {
    top: 16px;
    -webkit-animation-delay: .15s;
    animation-delay: .15s;
  }
  .scroll  span:nth-of-type(3) {
    top: 32px;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
  }
  @-webkit-keyframes sdb07 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb07 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}